import {
  Box,
  Center,
  Divider,
  Grid,
  GridItem,
  Heading,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react"
import { Link } from "gatsby"
import React from "react"

interface Props {
  children: any
}

const Container: React.FunctionComponent<Props> = ({ children }) => (
  <Box width="100%" px={4} paddingBottom={24}>
    <Center>
      <Box maxWidth={1440} width="100%">
        {children}
      </Box>
    </Center>
  </Box>
)

export default Container
